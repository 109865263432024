import React, { useState } from "react"
import Tab from "./Tab"
import TabPanel from "./TabPanel"
import * as styles from "./tabs.module.scss"

const Tabs = props => {
  const [activeTab, setActiveTab] = useState(props.children[0].props.label)

  const onClickTabItem = item => {
    setActiveTab(item.field_title)
    props.onTabChange(item)
  }

  return (
    <div>
      <ol className={styles.tabs}>
        {props.children.map((child, key) => {
          const { label } = child.props

          return (
            <Tab
              activeTab={activeTab}
              key={key}
              label={label}
              icon={child.props.icon}
              onClick={() => onClickTabItem(child.props.item)}
              layoutId={props.layoutId}
            />
          )
        })}
      </ol>

      {props.children.map((child, index) => {
        if (child.props.label !== activeTab) return undefined
        return (
          <TabPanel background_color={child.props.background_color} key={index}>
            {child.props.children}
          </TabPanel>
        )
      })}
    </div>
  )
}

export default Tabs
