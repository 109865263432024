import React from "react"
import * as styles from "./flavors.module.scss"

export const PrevButton = ({ enabled, onClick }) => (
  <button className={styles.prev} onClick={onClick} disabled={!enabled}>
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 1.74846e-06C8.9543 2.7141e-06 -2.7141e-06 8.95431 -1.74846e-06 20C-7.8281e-07 31.0457 8.95431 40 20 40C31.0457 40 40 31.0457 40 20C40 8.95431 31.0457 7.8281e-07 20 1.74846e-06ZM21.41 26.59L20 28L12 20L20 12L21.41 13.41L15.83 19L28 19L28 21L15.83 21L21.41 26.59Z"
        fill="#F6F7F7"
      />
    </svg>
  </button>
)

export const NextButton = ({ enabled, onClick }) => (
  <button className={styles.next} onClick={onClick} disabled={!enabled}>
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40ZM18.59 13.41L20 12L28 20L20 28L18.59 26.59L24.17 21H12V19H24.17L18.59 13.41Z"
        fill="#F6F7F7"
      />
    </svg>
  </button>
)
