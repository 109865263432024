import React from "react"
import * as styles from "./tabs.module.scss"
import { motion } from "framer-motion"

const Tab = ({ ...props }) => {
  const onClick = () => {
    props.onClick(props.label)
  }

  let isActive = ""
  if (props.activeTab === props.label) {
    isActive = styles.active
  }

  return (
    <li className={`${styles.tab} ${isActive}`} onClick={onClick}>
      {props.icon && (
        <img src={props.icon} alt="icon" className={styles.icon} />
      )}
      <span>{props.label}</span>
      {isActive && (
        <motion.div
          className={styles.border}
          layoutId={props.layoutId}
        ></motion.div>
      )}
    </li>
  )
}

export default Tab
