import React from "react"
import { colors } from "../../../config/colors"
import * as styles from "./tabs.module.scss"

const TabPanel = ({ ...props }) => {
  return (
    <section
      className={styles.panel}
      style={{ backgroundColor: colors[props.background_color] }}
    >
      {props.children}
    </section>
  )
}

export default TabPanel
