import PropTypes from "prop-types"
import React from "react"
import * as styles from "./categories.module.scss"
import Category from "./Category"

const Categories = ({ items }) => {
  return (
    <ul className={styles.categories}>
      {items &&
        items.map((category, key) => (
          <Category key={key} name={category.name} />
        ))}
    </ul>
  )
}

Categories.propTypes = {
  items: PropTypes.array,
}

Categories.defaultProps = {
  items: [],
}

export default Categories
