import { graphql } from "gatsby"
import React, { useState } from "react"
import { Heading } from "../components/01_atoms/Heading/Heading"
import { Paragraph } from "../components/01_atoms/Paragraph/Paragraph"
import HeroAnimation from "../components/03_organisms/animations/HeroAnimation/HeroAnimation"
import ImageTextBlock from "../components/03_organisms/ImageTextBlock/ImageTextBlock"
import Layout from "../components/layout"
import Seo from "../components/seo"

import * as tabStyles from "../components/02_molecules/Tabs/tabs.module.scss"
import * as componentStyles from "../components/components.module.scss"

import { motion } from "framer-motion"
import { useTranslation } from "react-i18next"
import Button from "../components/01_atoms/Button/Button"
import Categories from "../components/02_molecules/Categories/Categories"
import Tabs from "../components/02_molecules/Tabs/Tabs"
import Flavors from "../components/03_organisms/Flavors/Flavors"
import Section from "../components/03_organisms/Section/Section"
import {
  RenderComponent,
  RenderProductCarousel,
} from "../components/components"

const IndexPage = ({ data, pageContext }) => {
  const pageData = data.nodeProduct
  const visual = pageData?.relationships?.field_image
  const components = pageData?.relationships?.field_components
  const products = data?.allNodeProduct.nodes
  // const customization_options =
  //   pageData?.relationships?.field_components.field_customization_options

  // const off_the_shelf_solutions =
  //   pageData?.relationships?.field_components.field_off_the_shelf_solutions

  const spliceCurrentProduct = products.findIndex(object => {
    return object.path.alias === pageContext.slug
  })

  const { t } = useTranslation()

  const renderCustomizationOptions = props => {
    return (
      <Section content id="custom">
        <Heading size="xl" spacing="l">
          {props.field_title}
        </Heading>
        <div>
          {props.field_columns?.map((item, key) => (
            <div key={key}>
              <span
                dangerouslySetInnerHTML={{
                  __html: item.processed,
                }}
              />
            </div>
          ))}
        </div>
        <Tabs layoutId="customization_options" onTabChange={() => {}}>
          {props.relationships.field_product_customization_item.map(
            (item, key) => (
              <section
                key={key}
                item={item}
                label={item.field_title}
                icon={
                  item.relationships.field_image.relationships.field_media_image
                    .url
                }
                background_color={
                  item.relationships.field_background_color
                    ? item.relationships.field_background_color.name
                    : "Dusky Rose"
                }
              >
                <div className={tabStyles.panel_content}>
                  <Paragraph>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: item.field_content.processed,
                      }}
                    />
                  </Paragraph>
                </div>
                <motion.picture
                  className={tabStyles.panel_img}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                >
                  <img
                    alt={item.relationships.field_image.field_media_image.alt}
                    src={
                      item.relationships.field_image.relationships
                        .field_media_image.url
                    }
                  />
                </motion.picture>
              </section>
            )
          )}
        </Tabs>
        {props?.field_outro && (
          <div className={tabStyles.outro}>
            <Section align="center">
              <Paragraph align="center">
                <span
                  dangerouslySetInnerHTML={{
                    __html: props.field_outro?.processed,
                  }}
                />
              </Paragraph>
              {props.relationships.field_cta && (
                <Button
                  type="link"
                  to={props.relationships.field_cta[0]?.field_link.url}
                >
                  {props.relationships.field_cta[0]?.field_link.title}
                </Button>
              )}
            </Section>
          </div>
        )}
      </Section>
    )
  }

  const renderOffTheSelfSolutions = props => {
    const [currentFlavor, setCurrentFlavor] = useState(
      props.relationships.field_off_the_shelf_item[0].relationships
        .field_flavors[0]
    )

    return (
      <Section content background="Azure" id="off-the-shelf">
        <Heading size="xl" spacing="l">
          {props.field_title}
        </Heading>
        <div>
          {props.field_columns?.map((item, key) => (
            <div key={key}>
              <span
                dangerouslySetInnerHTML={{
                  __html: item.processed,
                }}
              />
            </div>
          ))}
        </div>

        <Tabs
          layoutId="off_the_shelf_solutions"
          onTabChange={item =>
            setCurrentFlavor(item.relationships.field_flavors[0])
          }
        >
          {props.relationships.field_off_the_shelf_item.map((item, key) => (
            <section
              key={key}
              item={item}
              label={item.field_title}
              background_color={
                currentFlavor?.relationships.field_background_color
                  ? currentFlavor.relationships.field_background_color.name
                  : "Dusky Rose"
              }
            >
              <div className={tabStyles.panel_content}>
                <Paragraph>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: item.field_content.processed,
                    }}
                  />
                </Paragraph>
                {item.field_title_off_the_shelf_flavor && (
                  <Heading size="s" spacing="m">
                    {item.field_title_off_the_shelf_flavor}
                  </Heading>
                )}
                {item.relationships.field_flavors.length > 0 && (
                  <Flavors
                    flavors={item.relationships.field_flavors}
                    onFlavorChange={flavor => {
                      setCurrentFlavor(flavor)
                    }}
                  />
                )}
              </div>
              <motion.picture
                className={tabStyles.panel_img}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
              >
                {/* <img
                  alt={
                    item.relationships.field_fafield_image.field_media_image
                      .alt
                  }
                  src={
                    item.relationships.field_image.relationships
                      .field_media_image.url
                  }
                /> */}

                {currentFlavor?.relationships.field_image && (
                  <motion.img
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    src={
                      currentFlavor.relationships.field_image.relationships
                        .field_media_image.url
                    }
                    alt={
                      currentFlavor.relationships.field_image.field_media_image
                        .alt
                    }
                  />
                )}
              </motion.picture>
            </section>
          ))}
        </Tabs>
        {props.field_outro && (
          <div className={tabStyles.outro}>
            <Section align="center">
              <Paragraph align="center">
                <span
                  dangerouslySetInnerHTML={{
                    __html: props.field_outro?.processed,
                  }}
                />
              </Paragraph>
              {/* {props.relationships.field_cta && (
              <Button type="link" to="/contact">
                {
                  props.relationships.field_cta[0]
                    ?.field_link.title
                }
              </Button>
            )} */}
            </Section>
          </div>
        )}
      </Section>
    )
  }

  return (
    <Layout>
      {pageData && (
        <>
          <Seo
            title={pageData.field_meta_title}
            description={pageData.field_meta_description}
          />
          <ImageTextBlock
            height="100"
            className="hero"
            image_position="right"
            background_color="Azure"
            animation={
              <HeroAnimation
                image={
                  <img
                    src={visual?.relationships.field_media_image.url}
                    alt={visual?.field_media_image.alt}
                  />
                }
                position="right"
                background_color={pageData.relationships.field_main_color.name}
              />
            }
          >
            <Categories items={pageData.relationships.field_categories} />
            <Heading size="xxl" spacing="m">
              {pageData.title}
            </Heading>
            <Paragraph>
              <span
                dangerouslySetInnerHTML={{
                  __html: pageData.body.processed,
                }}
              />
            </Paragraph>
          </ImageTextBlock>
        </>
      )}

      {components?.map((component, index) =>
        component.internal?.type === "paragraph__product_list" ? (
          RenderProductCarousel(component, index, products)
        ) : component.internal?.type === "paragraph__image_text" &&
          !component.field_fullwidth ? (
          <div className={componentStyles.spacing_top} key={index}>
            {RenderComponent(component, index, products)}
          </div>
        ) : component["__typename"] ===
          "paragraph__product_customization_collection" ? (
          renderCustomizationOptions(component)
        ) : component["__typename"] ===
          "paragraph__product_off_the_shelf_collection" ? (
          renderOffTheSelfSolutions(component)
        ) : (
          RenderComponent(component, index, products)
        )
      )}
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query ($language: String!, $slug: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    nodeProduct(
      path: { alias: { eq: $slug } }
      langcode: { eq: $language }
      status: { eq: true }
    ) {
      title
      field_short_description
      body {
        processed
      }
      path {
        alias
      }
      field_image {
        drupal_internal__target_id
      }
      field_meta_description
      field_meta_title
      relationships {
        field_image {
          ...imageFragment
        }
        field_categories {
          name
        }
        field_main_color {
          name
        }
        field_components {
          ... on Node {
            ...imageTextFragment
            ...productList
            ...videoFragment
            ...paragraph__card_collectionFragment
            ...paragraph__product_customization_collectionFragment
            ...paragraph__product_off_the_shelf_collectionFragment
          }
        }
      }
    }
    allNodeProduct(
      filter: { langcode: { eq: $language }, status: { eq: true } }
      sort: { fields: field_weight, order: ASC }
    ) {
      nodes {
        title
        field_short_description
        path {
          alias
          langcode
        }
        relationships {
          field_main_color {
            name
          }
          field_image {
            ...imageFragment
          }
        }
      }
      totalCount
    }
  }

  fragment paragraph__product_off_the_shelf_collectionFragment on paragraph__product_off_the_shelf_collection {
    __typename
    id
    field_columns {
      processed
    }
    field_outro {
      processed
    }
    field_title
    relationships {
      field_off_the_shelf_item {
        field_content {
          processed
        }
        field_title
        field_title_off_the_shelf_flavor
        relationships {
          field_flavors {
            field_title
            relationships {
              field_background_color {
                name
                internal {
                  type
                }
              }
              field_image {
                ...imageFragment
              }
              field_thumbnail {
                ...imageFragment
              }
            }
          }
        }
      }
    }
  }

  fragment paragraph__product_customization_collectionFragment on paragraph__product_customization_collection {
    __typename
    id
    field_columns {
      processed
    }
    relationships {
      field_cta {
        ...ctaFragment
      }
      field_product_customization_item {
        field_content {
          processed
        }
        field_title
        relationships {
          field_image {
            ...imageFragment
          }
          field_thumbnail {
            ...imageFragment
          }
          field_background_color {
            name
            internal {
              type
            }
          }
        }
      }
    }
    field_outro {
      processed
    }
    field_title
  }
`
