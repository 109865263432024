import { t } from "i18next"
import PropTypes from "prop-types"
import React from "react"
import * as styles from "./categories.module.scss"
import { useTranslation } from "react-i18next"

const Category = ({ name }) => {
  const { t } = useTranslation()

  return (
    <li
      className={styles[`category_${name.toLowerCase().replaceAll(" ", "_")}`]}
    >
      <span className={styles.icon} />
      <span className={styles.label}>
        {t(`category_${name.toLowerCase().replaceAll(" ", "_")}`)}
      </span>
    </li>
  )
}

Category.propTypes = {
  name: PropTypes.string,
}

Category.defaultProps = {
  name: ``,
}

export default Category
