import React, { useState, useCallback, useEffect } from "react"
import * as styles from "./flavors.module.scss"
import useEmblaCarousel from "embla-carousel-react"
import cx from "classnames"
import { NextButton, PrevButton } from "./EmblaButtons"

const Flavors = ({ flavors, onFlavorChange = () => undefined, ...props }) => {
  const [viewportRef, embla] = useEmblaCarousel({
    align: "start",
    dragFree: false,
    draggable: true,
    containScroll: "trimSnaps",
  })

  const [currentFlavor, setCurrentFlavor] = useState(flavors[0])
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false)
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false)

  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla])
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla])

  const onSelect = useCallback(() => {
    if (!embla) return
    setPrevBtnEnabled(embla.canScrollPrev())
    setNextBtnEnabled(embla.canScrollNext())
  }, [embla])

  useEffect(() => {
    if (!embla) return
    onSelect()
    embla.on("select", onSelect)
  }, [embla, onSelect])

  const onFlavorClick = flavor => {
    setCurrentFlavor(flavor)
    onFlavorChange(flavor)
  }

  return (
    <div className={styles.flavors}>
      <div className={styles.viewport} ref={viewportRef}>
        <div className={styles.container}>
          {flavors.map((flavor, index) => (
            <div
              key={index}
              className={cx(styles.flavor, {
                [styles.active]: flavor === currentFlavor,
              })}
              onClick={() => onFlavorClick(flavor)}
            >
              <img
                className={styles.thumbnail}
                src={
                  flavor.relationships.field_thumbnail.relationships
                    .field_media_image.url
                }
                alt={flavor.relationships.field_thumbnail.field_media_image.alt}
              />
              <div className={styles.title}>{flavor.field_title}</div>
            </div>
          ))}
        </div>
        <PrevButton onClick={scrollPrev} enabled={prevBtnEnabled} />
        <NextButton onClick={scrollNext} enabled={nextBtnEnabled} />
      </div>
    </div>
  )
}

export default Flavors
